<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-richMenu">
          <b-alert
            variant="success"
            dismissible
            fade
            :show="showAlertSuccess"
            @dismissed="showAlertSuccess = false"
          >
            {{ lbl['line-channel-theme-create-success-message'] }}
          </b-alert>
          <div class="row">
            <div class="col-12 page-header">
              <img
                :src="require('@/assets/images/line-channel-logo-header.png')"
                alt=""
              />
              {{ lbl['line-channel-header'] }}
            </div>
          </div>

          <a-form :form="form" @submit="handleSubmit">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="wrapper-blog-richmenu">
                  <div class="row">
                    <div
                      id="UserSelectedImageA"
                      class="
                        border
                        imagerich
                        col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                        p-0
                      "
                      :style="{
                        backgroundImage: 'url(' + imageMenuAUrl + ')',
                      }"
                    >
                      <div class="corner-flag-desc">A</div>
                      <div class="richemenu-corner-flag">
                        <img
                          src="@/assets/images/richmenu/bg-flag.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      id="UserSelectedImageB"
                      class="
                        border
                        imagerich
                        col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                        p-0
                      "
                      :style="{
                        backgroundImage: 'url(' + imageMenuBUrl + ')',
                      }"
                    >
                      <div class="corner-flag-desc">B</div>
                      <div class="richemenu-corner-flag">
                        <img
                          src="@/assets/images/richmenu/bg-flag.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      id="UserSelectedImageC"
                      class="
                        border
                        imagerich
                        col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                        p-0
                      "
                      :style="{
                        backgroundImage: 'url(' + imageMenuCUrl + ')',
                      }"
                    >
                      <div class="corner-flag-desc">C</div>
                      <div class="richemenu-corner-flag">
                        <img
                          src="@/assets/images/richmenu/bg-flag.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      id="UserSelectedImageD"
                      class="
                        border
                        imagerich
                        col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                        p-0
                      "
                      :style="{
                        backgroundImage: 'url(' + imageMenuDUrl + ')',
                      }"
                    >
                      <div class="corner-flag-desc">D</div>
                      <div class="richemenu-corner-flag">
                        <img
                          src="@/assets/images/richmenu/bg-flag.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      id="UserSelectedImageE"
                      class="
                        border
                        imagerich
                        col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                        p-0
                      "
                      :style="{
                        backgroundImage: 'url(' + imageMenuEUrl + ')',
                      }"
                    >
                      <div class="corner-flag-desc">E</div>
                      <div class="richemenu-corner-flag">
                        <img
                          src="@/assets/images/richmenu/bg-flag.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      id="UserSelectedImageF"
                      class="
                        border
                        imagerich
                        col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12
                        p-0
                      "
                      :style="{
                        backgroundImage: 'url(' + imageMenuFUrl + ')',
                      }"
                    >
                      <div class="corner-flag-desc">F</div>
                      <div class="richemenu-corner-flag">
                        <img
                          src="@/assets/images/richmenu/bg-flag.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12
                  pl-2
                  pr-5
                  my-auto
                  text-left
                "
              >
                <!-- A -->
                <div class="border mb-3">
                  <div class="corner-desc">
                    <span> A </span>
                    <span class="b-desc ml-2">{{ this.MessageHeader }}</span>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-md-5 text-center">
                        <img
                          id="imgMenuA"
                          ref="imgMenuA"
                          class="richemenu-upload-img-preview"
                          :src="imageMenuAUrl"
                          alt=""
                        />
                      </div>
                      <div class="col-md-7 my-auto pl-4">
                        <label for="ImageFileA" class="btn btn-upload-img">
                          <input
                            id="ImageFileA"
                            ref="refImageA"
                            type="file"
                            class="d-none"
                            accept="image/*"
                            @input="uploadImageA($event)"
                          />
                          <span class="px-2">
                            {{ lbl['pdpa-upload-menu'] }}</span
                          >
                        </label>
                        <br />
                        <span class="b-desc">{{
                          lbl['line-channel-theme-image-recommended-message2']
                        }}</span>
                        <br />
                        <img
                          @click="removeImageA"
                          class="visible-item delete-image"
                          src="@/assets/images/delete-item.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="b-desc pl-2">
                          {{ lbl['connection'] }}
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pl-4">
                        <span class="pl-2 pr-5"
                          ><a-radio-group
                            v-model="radioA"
                            @change="changeRadioA"
                          >
                            <a-radio class="pr-5" :value="1">
                              {{ lbl['line-channel-connection-page'] }}
                            </a-radio>
                            <a-radio class="pr-5 radio-link" :value="2">{{
                              lbl['line-channel-connection-link']
                            }}</a-radio>
                            <a-radio class="pr-5" :value="3">{{
                              lbl['line-channel-connection-campaign']
                            }}</a-radio>
                            <a-radio :value="4">{{ 'No Action' }}</a-radio>
                          </a-radio-group></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-md-12">
                      <a-form-item class="field-item">
                        <a-select
                          v-decorator="[
                            'connectionPageA',
                            {
                              initialValue: this.pageA ? this.pageA : [],
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isPageA"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="changePageA"
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionA.page"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionA.page }}
                        </a-select>

                        <a-input
                          v-decorator="[
                            'connectionLinkA',
                            {
                              initialValue: this.connectionA.link,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          @change="changeLinkA"
                          v-if="isLinkA"
                          placeholder="https://www.example.com"
                          size="large"
                        >
                          <a-icon
                            slot="suffix"
                            type="info-circle"
                            style="color: rgba(0, 0, 0, 0.45)"
                          />
                        </a-input>
                        <a-select
                          v-decorator="[
                            'connectionCampaignA',
                            {
                              initialValue: this.connectionA.camppaign,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isCampaignA"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="changeCampaignA"
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionA.campaign"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionA.campaign }}
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                  <!-- For Mobile -->
                  <div v-if="!isLinkA" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-mobile'] }}</span>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkA"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkA == '-'"
                        @click="copyUrl(copyLinkA, 'A')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <!-- For PC -->
                  <div v-if="!isLinkA" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-pc'] }}</span>
                  </div>
                  <div v-if="!isLinkA" class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkA_pc"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkA_pc == '-'"
                        @click="copyUrl(copyLinkA_pc, 'A')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <b-alert
                    variant="success"
                    dismissible
                    fade
                    :show="isCopiedA"
                    @dismissed="isCopiedA = false"
                  >
                    {{ lbl['copied-text'] }}
                  </b-alert>
                </div>

                <!-- B -->
                <div class="border mb-3">
                  <div class="corner-desc">
                    <span> B </span>
                    <span class="b-desc ml-2">{{ this.MessageHeader }}</span>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-md-5 text-center">
                        <img
                          id="imgMenuB"
                          ref="imgMenuB"
                          class="richemenu-upload-img-preview"
                          :src="imageMenuBUrl"
                          alt=""
                        />
                      </div>
                      <div class="col-md-7 my-auto pl-4">
                        <label for="ImageFileB" class="btn btn-upload-img">
                          <input
                            id="ImageFileB"
                            ref="refImageB"
                            type="file"
                            class="d-none"
                            accept="image/*"
                            @change="uploadImageB"
                          />
                          <span class="px-2">{{
                            lbl['pdpa-upload-menu']
                          }}</span>
                        </label>
                        <br />
                        <span class="b-desc">{{
                          lbl['line-channel-theme-image-recommended-message2']
                        }}</span>
                        <br />
                        <img
                          @click="removeImageB"
                          class="visible-item delete-image"
                          src="@/assets/images/delete-item.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="b-desc pl-2">
                          {{ lbl['connection'] }}
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pl-4">
                        <span class="pl-2 pr-5"
                          ><a-radio-group
                            v-model="radioB"
                            @change="changeRadioB"
                          >
                            <a-radio class="pr-5" :value="1">
                              {{ lbl['line-channel-connection-page'] }}
                            </a-radio>
                            <a-radio class="pr-5 radio-link" :value="2">{{
                              lbl['line-channel-connection-link']
                            }}</a-radio>
                            <a-radio class="pr-5" :value="3">{{
                              lbl['line-channel-connection-campaign']
                            }}</a-radio>
                            <a-radio :value="4">{{ 'No Action' }}</a-radio>
                          </a-radio-group></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-md-12">
                      <a-form-item class="field-item">
                        <a-select
                          v-decorator="[
                            'connectionPageB',
                            {
                              initialValue: this.pageB ? this.pageB : [],
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isPageB"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="changePageB"
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionB.page"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionB.page }}
                        </a-select>
                        <a-input
                          v-decorator="[
                            'connectionLinkB',
                            {
                              initialValue: this.connectionB.link,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isLinkB"
                          @change="changeLinkB"
                          placeholder="https://www.example.com"
                          size="large"
                        >
                          <a-icon
                            slot="suffix"
                            type="info-circle"
                            style="color: rgba(0, 0, 0, 0.45)"
                          />
                        </a-input>
                        <a-select
                          v-decorator="[
                            'connectionCampaignB',
                            {
                              initialValue: this.connectionB.camppaign,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isCampaignB"
                          size="large"
                          @change="changeCampaignB"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionB.campaign"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionB.campaign }}
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                  <!-- For Mobile -->
                  <div v-if="!isLinkB" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-mobile'] }}</span>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkB"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkB == '-'"
                        @click="copyUrl(copyLinkB, 'B')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <!-- For PC -->
                  <div v-if="!isLinkB" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-pc'] }}</span>
                  </div>
                  <div v-if="!isLinkB" class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkB_pc"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkB_pc == '-'"
                        @click="copyUrl(copyLinkB_pc, 'B')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <b-alert
                    variant="success"
                    dismissible
                    fade
                    :show="isCopiedB"
                    @dismissed="isCopiedB = false"
                  >
                    {{ lbl['copied-text'] }}
                  </b-alert>
                </div>

                <!-- C -->
                <div class="border mb-3">
                  <div class="corner-desc">
                    <span> C </span>
                    <span class="b-desc ml-2">{{ this.MessageHeader }}</span>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-md-5 text-center">
                        <img
                          id="imgMenuC"
                          ref="imgMenuC"
                          class="richemenu-upload-img-preview"
                          :src="imageMenuCUrl"
                          alt=""
                        />
                      </div>
                      <div class="col-md-7 my-auto pl-4">
                        <label for="ImageFileC" class="btn btn-upload-img">
                          <input
                            id="ImageFileC"
                            ref="refImageC"
                            type="file"
                            class="d-none"
                            accept="image/*"
                            @change="uploadImageC"
                          />
                          <span class="px-2">{{
                            lbl['pdpa-upload-menu']
                          }}</span>
                        </label>
                        <br />
                        <span class="b-desc">{{
                          lbl['line-channel-theme-image-recommended-message2']
                        }}</span>
                        <br />
                        <img
                          @click="removeImageC"
                          class="visible-item delete-image"
                          src="@/assets/images/delete-item.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="b-desc pl-2">
                          {{ lbl['connection'] }}
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pl-4">
                        <span class="pl-2 pr-5"
                          ><a-radio-group
                            v-model="radioC"
                            @change="changeRadioC"
                          >
                            <a-radio class="pr-5" :value="1">
                              {{ lbl['line-channel-connection-page'] }}
                            </a-radio>
                            <a-radio class="pr-5 radio-link" :value="2">{{
                              lbl['line-channel-connection-link']
                            }}</a-radio>
                            <a-radio class="pr-5" :value="3">{{
                              lbl['line-channel-connection-campaign']
                            }}</a-radio>
                            <a-radio :value="4">{{ 'No Action' }}</a-radio>
                          </a-radio-group></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-md-12">
                      <a-form-item class="field-item">
                        <a-select
                          v-decorator="[
                            'connectionPageC',
                            {
                              initialValue: this.pageC ? this.pageC : [],
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isPageC"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="changePageC"
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionC.page"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionC.page }}
                        </a-select>
                        <a-input
                          v-decorator="[
                            'connectionLinkC',
                            {
                              initialValue: this.connectionC.link,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isLinkC"
                          @change="changeLinkC"
                          placeholder="https://www.example.com"
                          size="large"
                        >
                          <a-icon
                            slot="suffix"
                            type="info-circle"
                            style="color: rgba(0, 0, 0, 0.45)"
                          />
                        </a-input>
                        <a-select
                          v-decorator="[
                            'connectionCampaignC',
                            {
                              initialValue: this.connectionC.camppaign,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isCampaignC"
                          size="large"
                          @change="changeCampaignC"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionC.campaign"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionC.campaign }}
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                  <!-- For Mobile -->
                  <div v-if="!isLinkC" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-mobile'] }}</span>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkC"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkC == '-'"
                        @click="copyUrl(copyLinkC, 'C')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <!-- For PC -->
                  <div v-if="!isLinkC" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-pc'] }}</span>
                  </div>
                  <div v-if="!isLinkC" class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkC_pc"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkC_pc == '-'"
                        @click="copyUrl(copyLinkC_pc, 'C')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <b-alert
                    variant="success"
                    dismissible
                    fade
                    :show="isCopiedC"
                    @dismissed="isCopiedC = false"
                  >
                    {{ lbl['copied-text'] }}
                  </b-alert>
                </div>

                <!-- D -->
                <div class="border mb-3">
                  <div class="corner-desc">
                    <span> D </span>
                    <span class="b-desc ml-2">{{ this.MessageHeader }}</span>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-md-5 text-center">
                        <img
                          id="imgMenuD"
                          ref="imgMenuD"
                          class="richemenu-upload-img-preview"
                          :src="imageMenuDUrl"
                          alt=""
                        />
                      </div>
                      <div class="col-md-7 my-auto pl-4">
                        <label for="ImageFileD" class="btn btn-upload-img">
                          <input
                            id="ImageFileD"
                            ref="refImageD"
                            type="file"
                            class="d-none"
                            accept="image/*"
                            @change="uploadImageD"
                          />
                          <span class="px-2">{{
                            lbl['pdpa-upload-menu']
                          }}</span>
                        </label>
                        <br />
                        <span class="b-desc">{{
                          lbl['line-channel-theme-image-recommended-message2']
                        }}</span>
                        <br />
                        <img
                          @click="removeImageD"
                          class="visible-item delete-image"
                          src="@/assets/images/delete-item.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="b-desc pl-2">
                          {{ lbl['connection'] }}
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pl-4">
                        <span class="pl-2 pr-5"
                          ><a-radio-group
                            v-model="radioD"
                            @change="changeRadioD"
                          >
                            <a-radio class="pr-5" :value="1">
                              {{ lbl['line-channel-connection-page'] }}
                            </a-radio>
                            <a-radio class="pr-5 radio-link" :value="2">{{
                              lbl['line-channel-connection-link']
                            }}</a-radio>
                            <a-radio class="pr-5" :value="3">{{
                              lbl['line-channel-connection-campaign']
                            }}</a-radio>
                            <a-radio :value="4">{{ 'No Action' }}</a-radio>
                          </a-radio-group></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-md-12">
                      <a-form-item class="field-item">
                        <a-select
                          v-decorator="[
                            'connectionPageD',
                            {
                              initialValue: this.pageD ? this.pageD : [],
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isPageD"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="changePageD"
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionD.page"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionD.page }}
                        </a-select>
                        <a-input
                          v-decorator="[
                            'connectionLinkD',
                            {
                              initialValue: this.connectionD.link,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isLinkD"
                          @change="changeLinkD"
                          placeholder="https://www.example.com"
                          size="large"
                        >
                          <a-icon
                            slot="suffix"
                            type="info-circle"
                            style="color: rgba(0, 0, 0, 0.45)"
                          />
                        </a-input>
                        <a-select
                          v-decorator="[
                            'connectionCampaignD',
                            {
                              initialValue: this.connectionD.camppaign,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isCampaignD"
                          size="large"
                          @change="changeCampaignD"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionD.campaign"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionD.campaign }}
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                  <!-- For Mobile -->
                  <div v-if="!isLinkD" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-mobile'] }}</span>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkD"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkD == '-'"
                        @click="copyUrl(copyLinkD, 'D')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <!-- For PC -->
                  <div v-if="!isLinkD" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-pc'] }}</span>
                  </div>
                  <div v-if="!isLinkD" class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkD_pc"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkD_pc == '-'"
                        @click="copyUrl(copyLinkD_pc, 'D')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <b-alert
                    variant="success"
                    dismissible
                    fade
                    :show="isCopiedD"
                    @dismissed="isCopiedD = false"
                  >
                    {{ lbl['copied-text'] }}
                  </b-alert>
                </div>

                <!-- E -->
                <div class="border mb-3">
                  <div class="corner-desc">
                    <span> E </span>
                    <span class="b-desc ml-2">{{ this.MessageHeader }}</span>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-md-5 text-center">
                        <img
                          id="imgMenuE"
                          ref="imgMenuE"
                          class="richemenu-upload-img-preview"
                          :src="imageMenuEUrl"
                          alt=""
                        />
                      </div>
                      <div class="col-md-7 my-auto pl-4">
                        <label for="ImageFileE" class="btn btn-upload-img">
                          <!-- <a-button class="btn-upload-img" @click="uploadImage">
                        Upload
                      </a-button> -->
                          <input
                            id="ImageFileE"
                            ref="refImageE"
                            type="file"
                            class="d-none"
                            accept="image/*"
                            @change="uploadImageE"
                          />
                          <span class="px-2">{{
                            lbl['pdpa-upload-menu']
                          }}</span>
                        </label>
                        <br />
                        <span class="b-desc">{{
                          lbl['line-channel-theme-image-recommended-message2']
                        }}</span>
                        <br />
                        <img
                          @click="removeImageE"
                          class="visible-item delete-image"
                          src="@/assets/images/delete-item.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="b-desc pl-2">
                          {{ lbl['connection'] }}
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pl-4">
                        <span class="pl-2 pr-5"
                          ><a-radio-group
                            v-model="radioE"
                            @change="changeRadioE"
                          >
                            <a-radio class="pr-5" :value="1">
                              {{ lbl['line-channel-connection-page'] }}
                            </a-radio>
                            <a-radio class="pr-5 radio-link" :value="2">{{
                              lbl['line-channel-connection-link']
                            }}</a-radio>
                            <a-radio class="pr-5" :value="3">{{
                              lbl['line-channel-connection-campaign']
                            }}</a-radio>
                            <a-radio :value="4">{{ 'No Action' }}</a-radio>
                          </a-radio-group></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-md-12">
                      <a-form-item class="field-item">
                        <a-select
                          v-decorator="[
                            'connectionPageE',
                            {
                              initialValue: this.pageE ? this.pageE : [],
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isPageE"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="changePageE"
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionE.page"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionE.page }}
                        </a-select>
                        <a-input
                          v-decorator="[
                            'connectionLinkE',
                            {
                              initialValue: this.connectionE.link,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isLinkE"
                          @change="changeLinkE"
                          placeholder="https://www.example.com"
                          size="large"
                        >
                          <a-icon
                            slot="suffix"
                            type="info-circle"
                            style="color: rgba(0, 0, 0, 0.45)"
                          />
                        </a-input>
                        <a-select
                          v-decorator="[
                            'connectionCampaignE',
                            {
                              initialValue: this.connectionE.camppaign,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isCampaignE"
                          size="large"
                          @change="changeCampaignE"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionE.campaign"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionE.campaign }}
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                  <!-- For Mobile -->
                  <div v-if="!isLinkE" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-mobile'] }}</span>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkE"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkE == '-'"
                        @click="copyUrl(copyLinkE, 'E')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <!-- For PC -->
                  <div v-if="!isLinkE" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-pc'] }}</span>
                  </div>
                  <div v-if="!isLinkE" class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkE_pc"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkE_pc == '-'"
                        @click="copyUrl(copyLinkE_pc, 'E')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <b-alert
                    variant="success"
                    dismissible
                    fade
                    :show="isCopiedE"
                    @dismissed="isCopiedE = false"
                  >
                    {{ lbl['copied-text'] }}
                  </b-alert>
                </div>

                <!-- F -->
                <div class="border mb-3">
                  <div class="corner-desc">
                    <span> F </span>
                    <span class="b-desc ml-2">{{ this.MessageHeader }}</span>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-md-5 text-center">
                        <img
                          id="imgMenuF"
                          ref="imgMenuF"
                          class="richemenu-upload-img-preview"
                          :src="imageMenuFUrl"
                          alt=""
                        />
                      </div>
                      <div class="col-md-7 my-auto pl-4">
                        <label for="ImageFileF" class="btn btn-upload-img">
                          <input
                            id="ImageFileF"
                            ref="refImageF"
                            type="file"
                            class="d-none"
                            accept="image/*"
                            @change="uploadImageF"
                          />
                          <span class="px-2">{{
                            lbl['pdpa-upload-menu']
                          }}</span>
                        </label>
                        <br />
                        <span class="b-desc">{{
                          lbl['line-channel-theme-image-recommended-message2']
                        }}</span>
                        <br />
                        <img
                          @click="removeImageF"
                          class="visible-item delete-image"
                          src="@/assets/images/delete-item.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="b-desc pl-2">
                          {{ lbl['connection'] }}
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 pl-4">
                        <span class="pl-2 pr-5"
                          ><a-radio-group
                            v-model="radioF"
                            @change="changeRadioF"
                          >
                            <a-radio class="pr-5" :value="1">
                              {{ lbl['line-channel-connection-page'] }}
                            </a-radio>
                            <a-radio class="pr-5 radio-link" :value="2">{{
                              lbl['line-channel-connection-link']
                            }}</a-radio>
                            <a-radio class="pr-5" :value="3">{{
                              lbl['line-channel-connection-campaign']
                            }}</a-radio>
                            <a-radio :value="4">{{ 'No Action' }}</a-radio>
                          </a-radio-group></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-md-12">
                      <a-form-item class="field-item">
                        <a-select
                          v-decorator="[
                            'connectionPageF',
                            {
                              initialValue: this.pageF ? this.pageF : [],
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isPageF"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="changePageF"
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionF.page"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionF.page }}
                        </a-select>
                        <a-input
                          v-decorator="[
                            'connectionLinkF',
                            {
                              initialValue: this.connectionF.link,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isLinkF"
                          @change="changeLinkF"
                          placeholder="https://www.example.com"
                          size="large"
                        >
                          <a-icon
                            slot="suffix"
                            type="info-circle"
                            style="color: rgba(0, 0, 0, 0.45)"
                          />
                        </a-input>
                        <a-select
                          v-decorator="[
                            'connectionCampaignF',
                            {
                              initialValue: this.connectionF.camppaign,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          v-if="isCampaignF"
                          size="large"
                          @change="changeCampaignF"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                        >
                          <a-spin slot="notFoundContent" size="small" />

                          <a-select-option
                            v-for="(item, index) in connectionF.campaign"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connectionF.campaign }}
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                  <!-- For Mobile -->
                  <div v-if="!isLinkF" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-mobile'] }}</span>
                  </div>
                  <div class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkF"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkF == '-'"
                        @click="copyUrl(copyLinkF, 'F')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <!-- For PC -->
                  <div v-if="!isLinkF" class="col-md-12">
                    <span>{{ this.lbl['liff-url-for-pc'] }}</span>
                  </div>
                  <div v-if="!isLinkF" class="row px-2 py-2">
                    <div class="col-sm-8">
                      <a-input
                        v-model="copyLinkF_pc"
                        size="large"
                        class="border-0 text-ellip"
                        style="background-color: #fff; color: #000"
                        :disabled="true"
                      >
                      </a-input>
                    </div>
                    <div class="col-sm-4 pl-0 text-right float-right">
                      <a-button
                        type="primary"
                        :disabled="copyLinkF_pc == '-'"
                        @click="copyUrl(copyLinkF_pc, 'F')"
                      >
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                  <b-alert
                    variant="success"
                    dismissible
                    fade
                    :show="isCopiedF"
                    @dismissed="isCopiedF = false"
                  >
                    {{ lbl['copied-text'] }}
                  </b-alert>
                </div>

                <!-- Submit -->
                <div class="mt-3 text-right btn-create">
                  <a-button type="primary" html-type="submit" shape="round">
                    {{ lbl['line-channel-theme-create-button'] }}
                  </a-button>
                </div>
              </div>
            </div>
          </a-form>
          <a-modal
            v-model="visibleUploadModal"
            :footer="null"
            centered
            :closable="false"
            :keyboard="false"
            :maskClosable="false"
            :class="[currentLocale]"
          >
            <div class="row">
              <div class="col-4 p-0">
                <img
                  class="image-fluid"
                  style="height: 10vw; max-width: 10vw"
                  :src="imageMenuAUrl"
                  alt=""
                />
              </div>
              <div class="col-4 p-0">
                <img
                  class="image-fluid"
                  style="height: 10vw; max-width: 10vw"
                  :src="imageMenuBUrl"
                  alt=""
                />
              </div>
              <div class="col-4 p-0">
                <img
                  class="image-fluid"
                  style="height: 10vw; max-width: 10vw"
                  :src="imageMenuCUrl"
                  alt=""
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4 p-0">
                <img
                  class="image-fluid"
                  style="height: 10vw; max-width: 10vw"
                  :src="imageMenuDUrl"
                  alt=""
                />
              </div>
              <div class="col-4 p-0">
                <img
                  class="image-fluid"
                  style="height: 10vw; max-width: 10vw"
                  :src="imageMenuEUrl"
                  alt=""
                />
              </div>
              <div class="col-4 p-0">
                <img
                  class="image-fluid"
                  style="height: 10vw; max-width: 10vw"
                  :src="imageMenuFUrl"
                  alt=""
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-2">
                <img
                  :src="require('@/assets/images/Rounded-stripes.gif')"
                  class="flag-language"
                  alt=""
                />
              </div>
            </div>
          </a-modal>
          <b-alert
            variant="success"
            dismissible
            fade
            :show="showSuccessCreateRichMenu"
            @dismissed="showSuccessCreateRichMenu = false"
          >
            {{ successCreateRichMenuText }}
          </b-alert>
          <b-alert
            variant="danger"
            dismissible
            fade
            :show="showErrorCreateRichMenu"
            @dismissed="showErrorCreateRichMenu = false"
          >
            {{ errorCreateRichMenuText }}
          </b-alert>
        </div>
      </div>
    </div>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import RichmenuTempl from '@/assets/file_templates/richmenu.json'
import fileReader from '@/helper/fileReader'
import Header from '@/components/Layout/Header'
import Cache from '@/helper/Cache.js'
import Locale from '@/helper/locale.js'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import Vue from 'vue'

export default {
  name: 'RichMenu',
  mixins: [Mixin],
  components: {
    Header,
    AlertModal,
  },
  data() {
    return {
      currentLocale: Locale.getLocaleShort(),
      MessageHeader: '',
      showAlertSuccess: false,
      imageMenu: require('@/assets/images/richmenu/default.jpg'),
      defaultImgMenuA: require('@/assets/images/richmenu/profile.jpg'),
      defaultImgMenuB: require('@/assets/images/richmenu/ecomurl.jpg'),
      defaultImgMenuC: require('@/assets/images/richmenu/campaigns.jpg'),
      defaultImgMenuD: require('@/assets/images/richmenu/earncode_stamp.jpg'),
      defaultImgMenuE: require('@/assets/images/richmenu/reward.jpg'),
      defaultImgMenuF: require('@/assets/images/richmenu/history.jpg'),
      defaultImgHome: require('@/assets/images/richmenu/home.jpg'),

      //A
      radioA: 1,
      imageMenuAUrl: null,
      isPageA: false,
      isLinkA: false,
      isCampaignA: false,
      connectionA: {
        page: [],
        link: '',
        campaign: [],
      },

      //B
      radioB: 1,
      imageMenuBUrl: null,
      isPageB: false,
      isLinkB: false,
      isCampaignB: false,
      connectionB: {
        page: [],
        link: '',
        campaign: [],
      },

      //C
      radioC: 1,
      imageMenuCUrl: null,
      isPageC: false,
      isLinkC: false,
      isCampaignC: false,
      connectionC: {
        page: [],
        link: '',
        campaign: [],
      },

      //D
      radioD: 2,
      imageMenuDUrl: null,
      isPageD: false,
      isLinkD: true,
      isCampaignD: false,
      connectionD: {
        page: [],
        link: '',
        campaign: [],
      },

      //E
      radioE: 2,
      imageMenuEUrl: null,
      isPageE: false,
      isLinkE: true,
      isCampaignE: false,
      connectionE: {
        page: [],
        link: '',
        campaign: [],
      },

      //F
      radioF: 2,
      imageMenuFUrl: null,
      isPageF: false,
      isLinkF: true,
      isCampaignF: false,
      connectionF: {
        page: [],
        link: '',
        campaign: [],
      },

      // API Parameters
      params: {
        campaignId: '',
        campaignIdA: '',
        campaignIdC: '',
        campaignIdD: '',
        campaignIdE: '',
        campaignIdF: '',
        redirect_url: '',
        redirect_url_a: '',
        redirect_url_c: '',
        redirect_url_d: '',
        redirect_url_e: '',
        redirect_url_f: '',
        json: '',
        background: '',
        imagemenua: '',
        imagemenub: '',
        imagemenuc: '',
        imagemenud: '',
        imagemenue: '',
        imagemenuf: '',
        generatenewbackground: false,
      },
      copyLinkA: '-',
      copyLinkB: '-',
      copyLinkC: '-',
      copyLinkD: '-',
      copyLinkE: '-',
      copyLinkF: '-',
      copyLinkA_pc: '-',
      copyLinkB_pc: '-',
      copyLinkC_pc: '-',
      copyLinkD_pc: '-',
      copyLinkE_pc: '-',
      copyLinkF_pc: '-',
      isCopiedA: false,
      isCopiedB: false,
      isCopiedC: false,
      isCopiedD: false,
      isCopiedE: false,
      isCopiedF: false,
      isDataImageA: false,
      isDataImageB: false,
      isDataImageC: false,
      isDataImageD: false,
      isDataImageE: false,
      isDataImageF: false,
      visibleUploadModal: false,
      showSuccessCreateRichMenu: false,
      showErrorCreateRichMenu: false,
      successCreateRichMenuText: '',
      errorCreateRichMenuText: '',
      pgA: '',
      pgB: '',
      pgC: '',
      pgD: '',
      pgE: '',
      pgF: '',
      actvCamp: [],
      liffLanding: '',
      AllowBrandA: null,
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      MAX_WIDTH: 833,
      MAX_HEIGHT: 843,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'config-richmenu',
    })
  },
  created() {
    this.init()
  },
  mounted() {
    if (Cache.get('Refresh')) {
      //do nothing
    } else {
      Cache.set('Refresh', true)
      location.reload()
    }
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.MessageHeader = this.lbl[
        'line-channel-theme-image-recommended-message3'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      this.getPageList()
      this.getCampaignList()
      this.getRichmenu()
      this.getCRMPlusProfile()
    },
    getPageList() {
      let data = [
        {
          id: 'home',
          Uri: '#webUrl#?target_page=home',
          Name: this.lbl['line-channel-select-home'],
        },
        {
          id: 'profile',
          Uri: '#webUrl#?target_page=profile',
          Name: this.lbl['line-channel-select-profile'],
        },
        {
          id: 'codescan',
          Uri: '#webUrl#?target_page=codescan',
          Name: this.lbl['line-channel-select-earn'],
        },
        {
          id: 'history',
          Uri: '#webUrl#?target_page=history',
          Name: this.lbl['line-channel-select-history'],
        },
      ]

      const selected = _.map(data, e => {
        return { id: e.Uri, name: e.Name }
      })
      this.connectionA.page = selected
      this.connectionB.page = selected
      this.connectionC.page = selected
      this.connectionD.page = selected
      this.connectionE.page = selected
      this.connectionF.page = selected
    },
    getCRMPlusProfile() {
      this.handleFooter(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            console.log('rishMenu getCRMPlusProfile --->', res.data)
            console.log('AllowBrandA --->', res.data.AllowBrandA)
            this.AllowBrandA = res.data.AllowBrandA
            resolve(res.data)
            this.handleFooter(false)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            resolve(error)
            this.handleFooter(false)
          })
      })
    },
    getCampaignList() {
      return new Promise(resolve => {
        BzbsSettingsLine.getCampaignListUrl()
          .then(res => {
            //Bug-223707 edit secct only active campaign.
            for (var a = 0; a < res.data.length; a++) {
              if (res.data[a].Status == 1 || res.data[a].Status == 3) {
                this.actvCamp.push(res.data[a])
              }
            }
            const selected = _.map(this.actvCamp, e => {
              return { id: e.ID, name: e.Name }
            })
            this.connectionA.campaign = selected
            this.connectionB.campaign = selected
            this.connectionC.campaign = selected
            this.connectionD.campaign = selected
            this.connectionE.campaign = selected
            this.connectionF.campaign = selected
            resolve(res)
          })
          .catch(error => {
            console.log('getCampaignListUrl error', error)
            resolve(error)
          })
      })
    },
    getRichmenu() {
      return new Promise(resolve => {
        BzbsSettingsLine.getConnectLine()
          .then(resLine => {
            if (!resLine.data.webHookUrl) {
              resolve(resLine)
              this.goto('RichMenu')
            }
            this.liffLanding = resLine.data.liffLanding
            BzbsSettingsLine.getLineRichMenu()
              .then(res => {
                console.log('getLineRichMenu : ', res)
                if (res) {
                  if (res.data) {
                    if (res.data.imageUrl) {
                      var urlversion =
                        res.data.imageUrl + '?v=' + new Date().getTime()
                      this.imageMenu = urlversion
                      fileReader.getBaseImage(urlversion).then(data => {
                        this.params.background = fileReader.blobToFile(
                          data,
                          'background',
                        )
                      })
                    }
                  }

                  //#region Menu Uri
                  let jsonContent = JSON.stringify(RichmenuTempl)
                  let jsonArr = JSON.parse(jsonContent)

                  var areasA = jsonArr.areas[0]
                  if (areasA) {
                    areasA.action.type = null
                    areasA.action.uri = null
                  }
                  var areasB = jsonArr.areas[1]
                  if (areasB) {
                    areasB.action.type = null
                    areasB.action.uri = null
                  }
                  var areasC = jsonArr.areas[2]
                  if (areasC) {
                    areasC.action.type = null
                    areasC.action.uri = null
                  }
                  var areasD = jsonArr.areas[3]
                  if (areasD) {
                    areasD.action.type = null
                    areasD.action.uri = null
                  }
                  var areasE = jsonArr.areas[4]
                  if (areasE) {
                    areasE.action.type = null
                    areasE.action.uri = null
                  }
                  var areasF = jsonArr.areas[5]
                  if (areasF) {
                    areasF.action.type = null
                    areasF.action.uri = null
                  }

                  if (res.data) {
                    if (res.data.menu) {
                      if (res.data.menu.areas) {
                        _.forEach(res.data.menu.areas, item => {
                          console.log('item : ', item)
                          if (item.bounds.x == 0 && item.bounds.y == 0) {
                            areasA = item
                          } else if (
                            item.bounds.x == 834 &&
                            item.bounds.y == 0
                          ) {
                            areasB = item
                          } else if (
                            item.bounds.x == 1667 &&
                            item.bounds.y == 0
                          ) {
                            areasC = item
                          } else if (
                            item.bounds.x == 0 &&
                            item.bounds.y == 843
                          ) {
                            areasD = item
                          } else if (
                            item.bounds.x == 834 &&
                            item.bounds.y == 843
                          ) {
                            areasE = item
                          } else if (
                            item.bounds.x == 1667 &&
                            item.bounds.y == 843
                          ) {
                            areasF = item
                          }
                        })
                      }
                    }
                  }

                  areasA = this.checkRichMenuUri(areasA)
                  areasB = this.checkRichMenuUri(areasB)
                  areasC = this.checkRichMenuUri(areasC)
                  areasD = this.checkRichMenuUri(areasD)
                  areasE = this.checkRichMenuUri(areasE)
                  areasF = this.checkRichMenuUri(areasF)

                  //#endregion

                  //#region Menu A
                  this.isLinkA = res.data.redirectUrlA ? true : false
                  this.isCampaignA = res.data.campaignIdA > 0
                  this.radioA = this.isLinkA
                    ? 2
                    : this.isCampaignA
                    ? 3
                    : areasA.isPage
                    ? 1
                    : 4
                  if (areasA) {
                    this.isPageA = areasA.isPage
                    this.pageA =
                      areasA.name == 'home'
                        ? this.connectionA.page[0].id
                        : areasA.name == 'history'
                        ? this.connectionA.page[3].id
                        : areasA.name == 'codescan'
                        ? this.connectionA.page[2].id
                        : areasA.name == 'profile'
                        ? this.connectionA.page[1].id
                        : ''

                    this.pgA = areasA.name
                    // Task 311747 by Achira on 202207161514
                    let strLiffLandingUrlA = '-'
                    if (this.radioA == 4) {
                      this.copyLinkA = '-'
                      this.copyLinkA_pc = '-'
                    } else if (areasA.action.uri)
                      strLiffLandingUrlA = areasA.action.uri
                    else strLiffLandingUrlA = this.liffLanding + '?target_page='

                    this.copyLinkA = strLiffLandingUrlA
                    this.copyLinkA_pc = strLiffLandingUrlA.replace(
                      'line://app/',
                      'https://liff.line.me/',
                    )
                    if (res.data.imageMenuAUrl) {
                      this.isDataImageA = this.checkDefaultImageMenu(
                        res.data.imageMenuAUrl,
                      )
                      var AUrl =
                        res.data.imageMenuAUrl + '?v=' + new Date().getTime()
                      this.imageMenuAUrl = AUrl
                      fileReader.getBaseImage(AUrl).then(data => {
                        this.params.imagemenua = fileReader.blobToFile(
                          data,
                          'imagemenua',
                        )
                      })
                    }
                  } else {
                    this.radioA = 1
                    this.isPageA = true
                    this.pageA = this.connectionA.page[1].id
                    this.imageMenuAUrl =
                      this.defaultImgMenuA + '?v=' + new Date().getTime()

                    fileReader.getBaseImage(this.imageMenuAUrl).then(data => {
                      this.params.imagemenua = fileReader.blobToFile(
                        data,
                        'imagemenua',
                      )
                    })
                  }

                  if (res.data.redirectUrlA) {
                    this.connectionA.link = res.data.redirectUrlA
                    this.copyLinkA = res.data.redirectUrlA
                  }

                  if (this.isCampaignA) {
                    this.connectionA.camppaign = res.data.campaignIdA
                  }

                  //#endregion

                  //#region Menu B
                  this.isLinkB = res.data.redirectUrl ? true : false
                  this.isCampaignB = res.data.campaignId > 0
                  this.radioB = this.isLinkB
                    ? 2
                    : this.isCampaignB
                    ? 3
                    : areasB.isPage
                    ? 1
                    : 4

                  if (areasB) {
                    this.isPageB = areasB.isPage
                    this.pageB =
                      areasB.name == 'home'
                        ? this.connectionB.page[0].id
                        : areasB.name == 'history'
                        ? this.connectionB.page[3].id
                        : areasB.name == 'codescan'
                        ? this.connectionB.page[2].id
                        : areasB.name == 'profile'
                        ? this.connectionB.page[1].id
                        : ''

                    this.pgB = areasB.name
                    // Task 311747 by Achira on 202207161514
                    let strLiffLandingUrlB = '-'
                    if (this.radioB == 4) {
                      this.copyLinkB = '-'
                      this.copyLinkB_pc = '-'
                    } else if (areasB.action.uri)
                      strLiffLandingUrlB = areasB.action.uri
                    else strLiffLandingUrlB = this.liffLanding + '?target_page='

                    this.copyLinkB = strLiffLandingUrlB
                    this.copyLinkB_pc = strLiffLandingUrlB.replace(
                      'line://app/',
                      'https://liff.line.me/',
                    )

                    if (res.data.imageMenuBUrl) {
                      this.isDataImageB = this.checkDefaultImageMenu(
                        res.data.imageMenuBUrl,
                      )
                      var BUrl =
                        res.data.imageMenuBUrl + '?v=' + new Date().getTime()
                      this.imageMenuBUrl = BUrl

                      fileReader.getBaseImage(BUrl).then(data => {
                        this.params.imagemenub = fileReader.blobToFile(
                          data,
                          'imagemenub',
                        )
                      })
                    }
                  } else {
                    this.radioB = 1
                    this.isPageB = true
                    this.pageB = this.connectionA.page[1].id
                    this.imageMenuBUrl =
                      this.defaultImgMenuB + '?v=' + new Date().getTime()

                    fileReader.getBaseImage(this.imageMenuBUrl).then(data => {
                      this.params.imagemenub = fileReader.blobToFile(
                        data,
                        'imagemenub',
                      )
                    })
                  }

                  if (res.data.redirectUrl) {
                    this.connectionB.link = res.data.redirectUrl
                    this.copyLinkB = res.data.redirectUrl
                  }

                  if (this.isCampaignB)
                    this.connectionB.camppaign = res.data.campaignId

                  //#endregion

                  //#region Menu C
                  this.isLinkC = res.data.redirectUrlC ? true : false
                  this.isCampaignC = res.data.campaignIdC > 0
                  this.radioC = this.isLinkC
                    ? 2
                    : this.isCampaignC
                    ? 3
                    : areasC.isPage
                    ? 1
                    : 4

                  if (areasC) {
                    this.isPageC = areasC.isPage
                    this.pageC =
                      areasC.name == 'home'
                        ? this.connectionC.page[0].id
                        : areasC.name == 'history'
                        ? this.connectionC.page[3].id
                        : areasC.name == 'codescan'
                        ? this.connectionC.page[2].id
                        : areasC.name == 'profile'
                        ? this.connectionC.page[1].id
                        : ''

                    this.pgC = areasC.name
                    // Task 311747 by Achira on 202207161514
                    let strLiffLandingUrlC = '-'
                    if (this.radioC == 4) {
                      this.copyLinkC = '-'
                      this.copyLinkC_pc = '-'
                    } else if (areasC.action.uri)
                      strLiffLandingUrlC = areasC.action.uri
                    else strLiffLandingUrlC = this.liffLanding + '?target_page='

                    this.copyLinkC = strLiffLandingUrlC
                    this.copyLinkC_pc = strLiffLandingUrlC.replace(
                      'line://app/',
                      'https://liff.line.me/',
                    )

                    if (res.data.imageMenuCUrl) {
                      this.isDataImageC = this.checkDefaultImageMenu(
                        res.data.imageMenuCUrl,
                      )
                      var CUrl =
                        res.data.imageMenuCUrl + '?v=' + new Date().getTime()
                      this.imageMenuCUrl = CUrl

                      fileReader.getBaseImage(CUrl).then(data => {
                        this.params.imagemenuc = fileReader.blobToFile(
                          data,
                          'imagemenuc',
                        )
                      })
                    }
                  } else {
                    this.radioC = 1
                    this.isPageC = true
                    this.pageC = this.connectionA.page[1].id
                    this.imageMenuCUrl =
                      this.defaultImgMenuA + '?v=' + new Date().getTime()

                    fileReader.getBaseImage(this.imageMenuCUrl).then(data => {
                      this.params.imagemenuc = fileReader.blobToFile(
                        data,
                        'imagemenuc',
                      )
                    })
                  }

                  if (res.data.redirectUrlC) {
                    this.connectionC.link = res.data.redirectUrlC
                    this.copyLinkC = res.data.redirectUrlC
                  }

                  if (this.isCampaignC)
                    this.connectionC.camppaign = res.data.campaignIdC

                  //#endregion

                  //#region Menu D
                  this.isLinkD = res.data.redirectUrlD ? true : false
                  this.isCampaignD = res.data.campaignIdD > 0
                  this.radioD = this.isLinkD
                    ? 2
                    : this.isCampaignD
                    ? 3
                    : areasD.isPage
                    ? 1
                    : 4

                  if (areasD) {
                    this.isPageD = areasD.isPage
                    this.pageD =
                      areasD.name == 'home'
                        ? this.connectionD.page[0].id
                        : areasD.name == 'history'
                        ? this.connectionD.page[3].id
                        : areasD.name == 'codescan'
                        ? this.connectionD.page[2].id
                        : areasD.name == 'profile'
                        ? this.connectionD.page[1].id
                        : ''

                    this.pgD = areasD.name
                    // Task 311747 by Achira on 202207161514
                    let strLiffLandingUrlD = '-'
                    if (this.radioD == 4) {
                      this.copyLinkD = '-'
                      this.copyLinkD_pc = '-'
                    } else if (areasD.action.uri)
                      strLiffLandingUrlD = areasD.action.uri
                    else strLiffLandingUrlD = this.liffLanding + '?target_page='

                    this.copyLinkD = strLiffLandingUrlD
                    this.copyLinkD_pc = strLiffLandingUrlD.replace(
                      'line://app/',
                      'https://liff.line.me/',
                    )

                    if (res.data.imageMenuDUrl) {
                      this.isDataImageD = this.checkDefaultImageMenu(
                        res.data.imageMenuDUrl,
                      )
                      var DUrl =
                        res.data.imageMenuDUrl + '?v=' + new Date().getTime()
                      this.imageMenuDUrl = DUrl

                      fileReader.getBaseImage(DUrl).then(data => {
                        this.params.imagemenud = fileReader.blobToFile(
                          data,
                          'imagemenud',
                        )
                      })
                    }
                  } else {
                    this.radioD = 1
                    this.isPageD = true
                    this.pageD = this.connectionA.page[1].id
                    this.imageMenuDUrl =
                      this.defaultImgMenuA + '?v=' + new Date().getTime()

                    fileReader.getBaseImage(this.imageMenuDUrl).then(data => {
                      this.params.imagemenud = fileReader.blobToFile(
                        data,
                        'imagemenud',
                      )
                    })
                  }

                  if (res.data.redirectUrlD) {
                    this.connectionD.link = res.data.redirectUrlD
                    this.copyLinkD = res.data.redirectUrlD
                  }

                  if (this.isCampaignD)
                    this.connectionD.camppaign = res.data.campaignIdD

                  //#endregion

                  //#region Menu E
                  this.isLinkE = res.data.redirectUrlE ? true : false
                  this.isCampaignE = res.data.campaignIdE > 0
                  this.radioE = this.isLinkE
                    ? 2
                    : this.isCampaignE
                    ? 3
                    : areasE.isPage
                    ? 1
                    : 4

                  if (areasE) {
                    this.isPageE = areasE.isPage
                    this.pageE =
                      areasE.name == 'home'
                        ? this.connectionE.page[0].id
                        : areasE.name == 'history'
                        ? this.connectionE.page[3].id
                        : areasE.name == 'codescan'
                        ? this.connectionE.page[2].id
                        : areasE.name == 'profile'
                        ? this.connectionE.page[1].id
                        : ''

                    this.pgE = areasE.name
                    // Task 311747 by Achira on 202207161514
                    let strLiffLandingUrlE = '-'
                    if (this.radioE == 4) {
                      this.copyLinkE = '-'
                      this.copyLinkE_pc = '-'
                    } else if (areasE.action.uri)
                      strLiffLandingUrlE = areasE.action.uri
                    else strLiffLandingUrlE = this.liffLanding + '?target_page='

                    this.copyLinkE = strLiffLandingUrlE
                    this.copyLinkE_pc = strLiffLandingUrlE.replace(
                      'line://app/',
                      'https://liff.line.me/',
                    )

                    if (res.data.imageMenuEUrl) {
                      this.isDataImageE = this.checkDefaultImageMenu(
                        res.data.imageMenuEUrl,
                      )
                      var EUrl =
                        res.data.imageMenuEUrl + '?v=' + new Date().getTime()
                      this.imageMenuEUrl = EUrl

                      fileReader.getBaseImage(EUrl).then(data => {
                        this.params.imagemenue = fileReader.blobToFile(
                          data,
                          'imagemenue',
                        )
                      })
                    }
                  } else {
                    this.radioE = 1
                    this.isPageE = true
                    this.pageE = this.connectionA.page[1].id
                    this.imageMenuEUrl =
                      this.defaultImgMenuA + '?v=' + new Date().getTime()

                    fileReader.getBaseImage(this.imageMenuEUrl).then(data => {
                      this.params.imagemenue = fileReader.blobToFile(
                        data,
                        'imagemenue',
                      )
                    })
                  }

                  if (res.data.redirectUrlE) {
                    this.connectionE.link = res.data.redirectUrlE
                    this.copyLinkE = res.data.redirectUrlE
                  }

                  if (this.isCampaignE)
                    this.connectionE.camppaign = res.data.campaignIdE

                  //#endregion

                  //#region Menu F
                  this.isLinkF = res.data.redirectUrlF ? true : false
                  this.isCampaignF = res.data.campaignIdF > 0
                  this.radioF = this.isLinkF
                    ? 2
                    : this.isCampaignF
                    ? 3
                    : areasF.isPage
                    ? 1
                    : 4

                  if (areasF) {
                    this.isPageF = areasF.isPage
                    this.pageF =
                      areasF.name == 'home'
                        ? this.connectionF.page[0].id
                        : areasF.name == 'history'
                        ? this.connectionF.page[3].id
                        : areasF.name == 'codescan'
                        ? this.connectionF.page[2].id
                        : areasF.name == 'profile'
                        ? this.connectionF.page[1].id
                        : ''

                    this.pgF = areasF.name
                    // Task 311747 by Achira on 202207161514
                    let strLiffLandingUrlF = '-'
                    if (this.radioF == 4) {
                      this.copyLinkF = '-'
                      this.copyLinkF_pc = '-'
                    } else if (areasF.action.uri)
                      strLiffLandingUrlF = areasF.action.uri
                    else strLiffLandingUrlF = this.liffLanding + '?target_page='

                    this.copyLinkF = strLiffLandingUrlF
                    this.copyLinkF_pc = strLiffLandingUrlF.replace(
                      'line://app/',
                      'https://liff.line.me/',
                    )

                    if (res.data.imageMenuFUrl) {
                      this.isDataImageF = this.checkDefaultImageMenu(
                        res.data.imageMenuFUrl,
                      )
                      var FUrl =
                        res.data.imageMenuFUrl + '?v=' + new Date().getTime()
                      this.imageMenuFUrl = FUrl

                      fileReader.getBaseImage(FUrl).then(data => {
                        this.params.imagemenuf = fileReader.blobToFile(
                          data,
                          'imagemenuf',
                        )
                      })
                    }
                  } else {
                    this.radioF = 1
                    this.isPageF = true
                    this.pageF = this.connectionA.page[1].id
                    this.imageMenuFUrl =
                      this.defaultImgMenuA + '?v=' + new Date().getTime()

                    fileReader.getBaseImage(this.imageMenuFUrl).then(data => {
                      this.params.imagemenuf = fileReader.blobToFile(
                        data,
                        'imagemenuf',
                      )
                    })
                  }

                  if (res.data.redirectUrlF) {
                    this.connectionF.link = res.data.redirectUrlF
                    this.copyLinkF = res.data.redirectUrlF
                  }

                  if (this.isCampaignF)
                    this.connectionF.camppaign = res.data.campaignIdF

                  //#endregion
                }
                resolve(res)
              })
              .catch(error => {
                console.log(error)
                resolve(error)
              })
          })
          .catch(errorLine => {
            console.log(errorLine)
            resolve(errorLine)
            this.goto('RichMenu')
          })
      })
    },
    checkDefaultImageMenu(image) {
      if (image) {
        let isMenu = image.includes(
          'menuA' || 'menuB' || 'menuC' || 'menuD' || 'menuE' || 'menuF',
        )
        if (isMenu) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    checkRichMenuUri(menu) {
      menu.isPage = false
      menu.name = ''
      if (menu.action.uri) {
        if (menu.action.uri.includes('home')) {
          menu.name = 'home'
          menu.isPage = true
        } else if (menu.action.uri.includes('profile')) {
          menu.name = 'profile'
          menu.isPage = true
        } else if (menu.action.uri.includes('history')) {
          menu.name = 'history'
          menu.isPage = true
        } else if (menu.action.uri.includes('codescan')) {
          menu.name = 'codescan'
          menu.isPage = true
        }
      }
      return menu
    },

    //#region ChangeRadioA

    changeRadioA(e) {
      console.log('changeRadioA : ', e.target.value, e)
      if (e.target.value == 2) {
        this.isPageA = false
        this.isLinkA = true
        this.isCampaignA = false
        if (!this.isDataImageA) {
          this.imageMenuAUrl =
            this.defaultImgMenuB + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuAUrl).then(data => {
            this.params.imagemenua = fileReader.blobToFile(data, 'imagemenua')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkA = this.connectionA.link
        this.copyLinkA_pc = this.copyLinkA.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 3) {
        this.isPageA = false
        this.isLinkA = false
        this.isCampaignA = true
        if (!this.isDataImageA) {
          this.imageMenuAUrl =
            this.defaultImgMenuC + '?v=' + new Date().getTime() //รูป default
          fileReader.getBaseImage(this.imageMenuAUrl).then(data => {
            this.params.imagemenua = fileReader.blobToFile(data, 'imagemenua')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkA = this.liffLanding
        if (this.connectionA.camppaign) {
          this.copyLinkA =
            this.liffLanding + '?taget_page=' + this.connectionA.camppaign
        }
        this.copyLinkA_pc = this.copyLinkA.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 4) {
        this.isPageA = false
        this.isLinkA = false
        this.isCampaignA = false
        this.copyLinkA = '-'
        this.copyLinkA_pc = '-'
        if (!this.isDataImageA) {
          this.imageMenuAUrl =
            this.defaultImgMenuA + '?v=' + new Date().getTime()

          fileReader.getBaseImage(this.imageMenuAUrl).then(data => {
            this.params.imagemenua = fileReader.blobToFile(data, 'imagemenua')
          })
        }
      } else {
        this.isPageA = true
        this.isLinkA = false
        this.isCampaignA = false
        if (!this.isDataImageA) {
          this.imageMenuAUrl =
            this.pgA == 'home'
              ? this.defaultImgHome + '?v=' + new Date().getTime()
              : this.pgA == 'history'
              ? this.defaultImgMenuF + '?v=' + new Date().getTime()
              : this.pgA == 'codescan'
              ? this.defaultImgMenuD + '?v=' + new Date().getTime()
              : this.defaultImgMenuA + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuAUrl).then(data => {
            this.params.imagemenua = fileReader.blobToFile(data, 'imagemenua')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkA = this.liffLanding
        if (this.pgA) {
          this.copyLinkA = this.liffLanding + '?taget_page=' + this.pgA
        }
        this.copyLinkA_pc = this.copyLinkA.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      }
    },
    removeImageA() {
      console.log('removeImageA : ', true)
      this.isDataImageA = false
      this.imageMenuAUrl = this.defaultImgMenuA + '?v=' + new Date().getTime()
    },

    //#endregion

    //#region ChangeRadioB

    changeRadioB(e) {
      console.log('changeRadioB : ', e.target.value, e)
      if (e.target.value == 2) {
        this.isPageB = false
        this.isLinkB = true
        this.isCampaignB = false
        if (!this.isDataImageB) {
          this.imageMenuBUrl =
            this.defaultImgMenuB + '?v=' + new Date().getTime() //รูป default
          fileReader.getBaseImage(this.imageMenuBUrl).then(data => {
            this.params.imagemenub = fileReader.blobToFile(data, 'imagemenub')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkB = this.connectionB.link
        this.copyLinkB_pc = this.copyLinkB.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 3) {
        this.isPageB = false
        this.isLinkB = false
        this.isCampaignB = true
        if (!this.isDataImageB) {
          this.imageMenuBUrl =
            this.defaultImgMenuC + '?v=' + new Date().getTime() //รูป default
          fileReader.getBaseImage(this.imageMenuBUrl).then(data => {
            this.params.imagemenub = fileReader.blobToFile(data, 'imagemenub')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkB = this.liffLanding
        if (this.connectionB.camppaign) {
          this.copyLinkB =
            this.liffLanding + '?taget_page=' + this.connectionB.camppaign
        }
        this.copyLinkB_pc = this.copyLinkB.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 4) {
        this.isPageB = false
        this.isLinkB = false
        this.isCampaignB = false
        this.copyLinkB = '-'
        this.copyLinkB_pc = '-'
        if (!this.isDataImageB) {
          this.imageMenuBUrl =
            this.defaultImgMenuB + '?v=' + new Date().getTime()

          fileReader.getBaseImage(this.imageMenuBUrl).then(data => {
            this.params.imagemenub = fileReader.blobToFile(data, 'imagemenub')
          })
        }
      } else {
        this.isPageB = true
        this.isLinkB = false
        this.isCampaignB = false
        if (!this.isDataImageB) {
          this.imageMenuBUrl =
            this.pgB == 'home'
              ? this.defaultImgHome + '?v=' + new Date().getTime()
              : this.pgB == 'history'
              ? this.defaultImgMenuF + '?v=' + new Date().getTime()
              : this.pgB == 'codescan'
              ? this.defaultImgMenuD + '?v=' + new Date().getTime()
              : this.defaultImgMenuA + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuBUrl).then(data => {
            this.params.imagemenub = fileReader.blobToFile(data, 'imagemenub')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkB = this.liffLanding
        if (this.pgB) {
          this.copyLinkB = this.liffLanding + '?taget_page=' + this.pgB
        }
        this.copyLinkB_pc = this.copyLinkB.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      }
    },
    removeImageB() {
      console.log('removeImageB : ', true)
      this.isDataImageB = false
      this.imageMenuBUrl = this.defaultImgMenuB + '?v=' + new Date().getTime()
    },
    //#endregion

    //#region ChangeRadioC

    changeRadioC(e) {
      console.log('changeRadioC : ', e.target.value, e)
      if (e.target.value == 2) {
        this.isPageC = false
        this.isLinkC = true
        this.isCampaignC = false
        if (!this.isDataImageC) {
          this.imageMenuCUrl =
            this.defaultImgMenuB + '?v=' + new Date().getTime() //รูป default
          fileReader.getBaseImage(this.imageMenuCUrl).then(data => {
            this.params.imagemenuc = fileReader.blobToFile(data, 'imagemenuc')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkC = this.connectionC.link
        this.copyLinkC_pc = this.copyLinkC.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 3) {
        this.isPageC = false
        this.isLinkC = false
        this.isCampaignC = true
        if (!this.isDataImageC) {
          this.imageMenuCUrl =
            this.defaultImgMenuC + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuCUrl).then(data => {
            this.params.imagemenuc = fileReader.blobToFile(data, 'imagemenuc')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkC = this.liffLanding
        if (this.connectionC.camppaign) {
          this.copyLinkC =
            this.liffLanding + '?taget_page=' + this.connectionC.camppaign
        }
        this.copyLinkC_pc = this.copyLinkC.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 4) {
        this.isPageC = false
        this.isLinkC = false
        this.isCampaignC = false
        this.copyLinkC = '-'
        this.copyLinkC_pc = '-'

        if (!this.isDataImageC) {
          this.imageMenuCUrl =
            this.defaultImgMenuC + '?v=' + new Date().getTime()

          fileReader.getBaseImage(this.imageMenuCUrl).then(data => {
            this.params.imagemenuc = fileReader.blobToFile(data, 'imagemenuc')
          })
        }
      } else {
        this.isPageC = true
        this.isLinkC = false
        this.isCampaignC = false
        if (!this.isDataImageC) {
          this.imageMenuCUrl =
            this.pgC == 'home'
              ? this.defaultImgHome + '?v=' + new Date().getTime()
              : this.pgC == 'history'
              ? this.defaultImgMenuF + '?v=' + new Date().getTime()
              : this.pgC == 'codescan'
              ? this.defaultImgMenuD + '?v=' + new Date().getTime()
              : this.defaultImgMenuA + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuCUrl).then(data => {
            this.params.imagemenuc = fileReader.blobToFile(data, 'imagemenuc')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkC = this.liffLanding
        if (this.pgC) {
          this.copyLinkC = this.liffLanding + '?taget_page=' + this.pgC
        }
        this.copyLinkC_pc = this.copyLinkC.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      }
    },
    removeImageC() {
      console.log('removeImageC : ', true)
      this.isDataImageC = false
      this.imageMenuCUrl = this.defaultImgMenuC + '?v=' + new Date().getTime()
    },

    //#endregion

    //#region ChangeRadioD

    changeRadioD(e) {
      console.log('changeRadioD : ', e.target.value, e)
      if (e.target.value == 2) {
        this.isPageD = false
        this.isLinkD = true
        this.isCampaignD = false
        if (!this.isDataImageD) {
          this.imageMenuDUrl =
            this.defaultImgMenuB + '?v=' + new Date().getTime() //รูป default
          fileReader.getBaseImage(this.imageMenuDUrl).then(data => {
            this.params.imagemenud = fileReader.blobToFile(data, 'imagemenud')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkD = this.connectionD.link
        this.copyLinkD_pc = this.copyLinkD.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 3) {
        this.isPageD = false
        this.isLinkD = false
        this.isCampaignD = true
        if (!this.isDataImageD) {
          this.imageMenuDUrl =
            this.defaultImgMenuC + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuDUrl).then(data => {
            this.params.imagemenud = fileReader.blobToFile(data, 'imagemenud')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkD = this.liffLanding
        if (this.connectionD.camppaign) {
          this.copyLinkD =
            this.liffLanding + '?taget_page=' + this.connectionD.camppaign
        }
        this.copyLinkD_pc = this.copyLinkD.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 4) {
        this.isPageD = false
        this.isLinkD = false
        this.isCampaignD = false
        this.copyLinkD = '-'
        this.copyLinkD_pc = '-'
        if (!this.isDataImageD) {
          this.imageMenuDUrl =
            this.defaultImgMenuD + '?v=' + new Date().getTime()

          fileReader.getBaseImage(this.imageMenuDUrl).then(data => {
            this.params.imagemenud = fileReader.blobToFile(data, 'imagemenud')
          })
        }
      } else {
        this.isPageD = true
        this.isLinkD = false
        this.isCampaignD = false
        if (!this.isDataImageD) {
          this.imageMenuDUrl =
            this.pgD == 'home'
              ? this.defaultImgHome + '?v=' + new Date().getTime()
              : this.pgD == 'history'
              ? this.defaultImgMenuF + '?v=' + new Date().getTime()
              : this.pgD == 'codescan'
              ? this.defaultImgMenuD + '?v=' + new Date().getTime()
              : this.defaultImgMenuA + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuDUrl).then(data => {
            this.params.imagemenud = fileReader.blobToFile(data, 'imagemenud')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkD = this.liffLanding
        if (this.pgD) {
          this.copyLinkD = this.liffLanding + '?taget_page=' + this.pgD
        }
        this.copyLinkD_pc = this.copyLinkD.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      }
    },
    removeImageD() {
      console.log('removeImageD : ', true)
      this.isDataImageD = false
      this.imageMenuDUrl = this.defaultImgMenuD + '?v=' + new Date().getTime()
    },

    //#endregion

    //#region ChangeRadioE

    changeRadioE(e) {
      console.log('changeRadioE : ', e.target.value, e)
      if (e.target.value == 2) {
        this.isPageE = false
        this.isLinkE = true
        this.isCampaignE = false
        if (!this.isDataImageE) {
          this.imageMenuEUrl =
            this.defaultImgMenuB + '?v=' + new Date().getTime() //รูป default
          fileReader.getBaseImage(this.imageMenuEUrl).then(data => {
            this.params.imagemenue = fileReader.blobToFile(data, 'imagemenue')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkE = this.connectionE.link
        this.copyLinkE_pc = this.copyLinkE.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 3) {
        this.isPageE = false
        this.isLinkE = false
        this.isCampaignE = true
        if (!this.isDataImageE) {
          this.imageMenuEUrl =
            this.defaultImgMenuC + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuEUrl).then(data => {
            this.params.imagemenue = fileReader.blobToFile(data, 'imagemenue')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkE = this.liffLanding
        if (this.connectionE.camppaign) {
          this.copyLinkE =
            this.liffLanding + '?taget_page=' + this.connectionE.camppaign
        }
        this.copyLinkE_pc = this.copyLinkE.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 4) {
        this.isPageE = false
        this.isLinkE = false
        this.isCampaignE = false
        this.copyLinkE = '-'
        this.copyLinkE_pc = '-'
        if (!this.isDataImageE) {
          this.imageMenuEUrl =
            this.defaultImgMenuE + '?v=' + new Date().getTime()

          fileReader.getBaseImage(this.imageMenuEUrl).then(data => {
            this.params.imagemenue = fileReader.blobToFile(data, 'imagemenue')
          })
        }
      } else {
        this.isPageE = true
        this.isLinkE = false
        this.isCampaignE = false
        if (!this.isDataImageE) {
          this.imageMenuEUrl =
            this.pgE == 'home'
              ? this.defaultImgHome + '?v=' + new Date().getTime()
              : this.pgE == 'history'
              ? this.defaultImgMenuF + '?v=' + new Date().getTime()
              : this.pgE == 'codescan'
              ? this.defaultImgMenuD + '?v=' + new Date().getTime()
              : this.defaultImgMenuA + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuEUrl).then(data => {
            this.params.imagemenue = fileReader.blobToFile(data, 'imagemenue')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkE = this.liffLanding
        if (this.pgE) {
          this.copyLinkE = this.liffLanding + '?taget_page=' + this.pgE
        }
        this.copyLinkE_pc = this.copyLinkE.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      }
    },
    removeImageE() {
      console.log('removeImageE : ', true)
      this.isDataImageE = false
      this.imageMenuEUrl = this.defaultImgMenuE + '?v=' + new Date().getTime()
    },

    //#endregion

    //#region ChangeRadioF

    changeRadioF(e) {
      console.log('changeRadioF : ', e.target.value, e)
      if (e.target.value == 2) {
        this.isPageF = false
        this.isLinkF = true
        this.isCampaignF = false
        if (!this.isDataImageF) {
          this.imageMenuFUrl =
            this.defaultImgMenuB + '?v=' + new Date().getTime() //รูป default
          fileReader.getBaseImage(this.imageMenuFUrl).then(data => {
            this.params.imagemenuf = fileReader.blobToFile(data, 'imagemenuf')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkF = this.connectionF.link
        this.copyLinkF_pc = this.copyLinkF.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 3) {
        this.isPageF = false
        this.isLinkF = false
        this.isCampaignF = true
        if (!this.isDataImageF) {
          this.imageMenuFUrl =
            this.defaultImgMenuC + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuFUrl).then(data => {
            this.params.imagemenuf = fileReader.blobToFile(data, 'imagemenuf')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkF = this.liffLanding
        if (this.connectionF.camppaign) {
          this.copyLinkF =
            this.liffLanding + '?taget_page=' + this.connectionF.camppaign
        }
        this.copyLinkF_pc = this.copyLinkF.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      } else if (e.target.value == 4) {
        this.isPageF = false
        this.isLinkF = false
        this.isCampaignF = false
        this.copyLinkF = '-'
        this.copyLinkF_pc = '-'
        if (!this.isDataImageF) {
          this.imageMenuFUrl =
            this.defaultImgMenuF + '?v=' + new Date().getTime()

          fileReader.getBaseImage(this.imageMenuFUrl).then(data => {
            this.params.imagemenuf = fileReader.blobToFile(data, 'imagemenuf')
          })
        }
      } else {
        this.isPageF = true
        this.isLinkF = false
        this.isCampaignF = false
        if (!this.isDataImageF) {
          this.imageMenuFUrl =
            this.pgF == 'home'
              ? this.defaultImgHome + '?v=' + new Date().getTime()
              : this.pgF == 'history'
              ? this.defaultImgMenuF + '?v=' + new Date().getTime()
              : this.pgF == 'codescan'
              ? this.defaultImgMenuD + '?v=' + new Date().getTime()
              : this.defaultImgMenuA + '?v=' + new Date().getTime() //รูป default

          fileReader.getBaseImage(this.imageMenuFUrl).then(data => {
            this.params.imagemenuf = fileReader.blobToFile(data, 'imagemenuf')
          })
        }

        // Task 311747 by Achira on 202207161514
        this.copyLinkF = this.liffLanding
        if (this.pgF) {
          this.copyLinkF = this.liffLanding + '?taget_page=' + this.pgF
        }
        this.copyLinkF_pc = this.copyLinkF.replace(
          'line://app/',
          'https://liff.line.me/',
        )
      }
    },
    removeImageF() {
      console.log('removeImageF : ', true)
      this.isDataImageF = false
      this.imageMenuFUrl = this.defaultImgMenuF + '?v=' + new Date().getTime()
    },

    //#endregion

    checkImg(file) {
      var allowType = ['image/jpeg', 'image/jpg', 'image/png']
      var maxSize = 1024 * 1024 * Vue.bzbsConfig.businessRule.max.photoSize
      var isCorrectType = allowType.includes(file.type)
      var isCorrectSize = file.size <= maxSize
      return isCorrectType && isCorrectSize
    },
    reSizeImg(file) {
      const reader = new FileReader()
      const image = new Image()
      const canvas = document.createElement('canvas')
      const resize = () => {
        let { width, height } = image

        if (width > height) {
          if (width > this.MAX_WIDTH) {
            height *= this.MAX_WIDTH / width
            width = this.MAX_WIDTH
          }
        } else if (height > this.MAX_HEIGHT) {
          width *= this.MAX_HEIGHT / height
          height = this.MAX_HEIGHT
        }

        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)

        const dataUrl = canvas.toDataURL('image/jpeg')
        return fileReader.dataURLtoFile(dataUrl, file.name)
      }

      return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error('Not an image'))
          return
        }

        reader.onload = readerEvent => {
          image.onload = () => ok(resize())
          image.src = readerEvent.target.result
        }

        reader.readAsDataURL(file)
      })
    },
    uploadImageA(event) {
      var uploadFileA = event.target.files[0]
      var lblmaximumConfig = this.lbl[
        'plan-reward-stamp-campaign-manage-image-recommended-message'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      if (this.checkImg(uploadFileA)) {
        this.isDataImageA = true
        var afterDot = uploadFileA.name.substr(0, uploadFileA.name.indexOf('.'))
        var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
        var newFileName = nonSpacial.replace(/\s/g, '_')
        var resultFile = new File([uploadFileA], newFileName, {
          type: uploadFileA.type,
        })
        this.imageMenuAUrl = URL.createObjectURL(resultFile)

        this.reSizeImg(uploadFileA).then(result => {
          this.params.imagemenua = result
        })
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          lblmaximumConfig,
          'error',
          true,
        )
      }
    },
    uploadImageB(event) {
      var uploadFileB = event.target.files[0]
      var lblmaximumConfig = this.lbl[
        'plan-reward-stamp-campaign-manage-image-recommended-message'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      if (this.checkImg(uploadFileB)) {
        this.isDataImageB = true
        var afterDot = uploadFileB.name.substr(0, uploadFileB.name.indexOf('.'))
        var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
        var newFileName = nonSpacial.replace(/\s/g, '_')
        var resultFile = new File([uploadFileB], newFileName, {
          type: uploadFileB.type,
        })
        this.imageMenuBUrl = URL.createObjectURL(resultFile)

        this.reSizeImg(uploadFileB).then(result => {
          this.params.imagemenub = result
        })
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          lblmaximumConfig,
          'error',
          true,
        )
      }
    },
    uploadImageC(event) {
      var uploadFileC = event.target.files[0]
      var lblmaximumConfig = this.lbl[
        'plan-reward-stamp-campaign-manage-image-recommended-message'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      if (this.checkImg(uploadFileC)) {
        this.isDataImageC = true
        var afterDot = uploadFileC.name.substr(0, uploadFileC.name.indexOf('.'))
        var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
        var newFileName = nonSpacial.replace(/\s/g, '_')
        var resultFile = new File([uploadFileC], newFileName, {
          type: uploadFileC.type,
        })
        this.imageMenuCUrl = URL.createObjectURL(resultFile)

        this.reSizeImg(uploadFileC).then(result => {
          this.params.imagemenuc = result
        })
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          lblmaximumConfig,
          'error',
          true,
        )
      }
    },
    uploadImageD(event) {
      var uploadFileD = event.target.files[0]
      var lblmaximumConfig = this.lbl[
        'plan-reward-stamp-campaign-manage-image-recommended-message'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      if (this.checkImg(uploadFileD)) {
        this.isDataImageD = true
        var afterDot = uploadFileD.name.substr(0, uploadFileD.name.indexOf('.'))
        var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
        var newFileName = nonSpacial.replace(/\s/g, '_')
        var resultFile = new File([uploadFileD], newFileName, {
          type: uploadFileD.type,
        })
        this.imageMenuDUrl = URL.createObjectURL(resultFile)

        this.reSizeImg(uploadFileD).then(result => {
          this.params.imagemenud = result
        })
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          lblmaximumConfig,
          'error',
          true,
        )
      }
    },
    uploadImageE(event) {
      var uploadFileE = event.target.files[0]
      var lblmaximumConfig = this.lbl[
        'plan-reward-stamp-campaign-manage-image-recommended-message'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      if (this.checkImg(uploadFileE)) {
        this.isDataImageE = true
        var afterDot = uploadFileE.name.substr(0, uploadFileE.name.indexOf('.'))
        var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
        var newFileName = nonSpacial.replace(/\s/g, '_')
        var resultFile = new File([uploadFileE], newFileName, {
          type: uploadFileE.type,
        })
        this.imageMenuEUrl = URL.createObjectURL(resultFile)

        this.reSizeImg(uploadFileE).then(result => {
          this.params.imagemenue = result
        })
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          lblmaximumConfig,
          'error',
          true,
        )
      }
    },
    uploadImageF(event) {
      var uploadFileF = event.target.files[0]
      var lblmaximumConfig = this.lbl[
        'plan-reward-stamp-campaign-manage-image-recommended-message'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      if (this.checkImg(uploadFileF)) {
        this.isDataImageF = true
        var afterDot = uploadFileF.name.substr(0, uploadFileF.name.indexOf('.'))
        var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
        var newFileName = nonSpacial.replace(/\s/g, '_')
        var resultFile = new File([uploadFileF], newFileName, {
          type: uploadFileF.type,
        })
        this.imageMenuFUrl = URL.createObjectURL(resultFile)
        this.reSizeImg(uploadFileF).then(result => {
          this.params.imagemenuf = result
        })
      } else {
        this.alertModalAction(
          this.lbl['alert-box-error-header'],
          lblmaximumConfig,
          'error',
          true,
        )
      }
    },
    copyUrl(linkData, menuname) {
      const el = document.createElement('textarea')

      el.value = linkData
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }

      switch (menuname) {
        case 'A':
          this.isCopiedA = true
          setTimeout(() => {
            this.isCopiedA = false
          }, 3000)
          break
        case 'B':
          this.isCopiedB = true
          setTimeout(() => {
            this.isCopiedB = false
          }, 3000)
          break
        case 'C':
          this.isCopiedC = true
          setTimeout(() => {
            this.isCopiedC = false
          }, 3000)
          break
        case 'D':
          this.isCopiedD = true
          setTimeout(() => {
            this.isCopiedD = false
          }, 3000)
          break
        case 'E':
          this.isCopiedE = true
          setTimeout(() => {
            this.isCopiedE = false
          }, 3000)
          break
        case 'F':
          this.isCopiedF = true
          setTimeout(() => {
            this.isCopiedF = false
          }, 3000)
          break
        default:
          console.log('Default copy')
      }
    },
    changePageA(e) {
      var linkPage = e.replace('#webUrl#', this.liffLanding)
      this.copyLinkA = linkPage
      this.copyLinkA_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
      if (!this.isDataImageA) {
        this.imageMenuAUrl = this.getDefaultMenuImage(e)
        fileReader.getBaseImage(this.imageMenuAUrl).then(data => {
          this.params.imagemenua = fileReader.blobToFile(data, 'imagemenua')
        })
      }
    },
    changePageB(e) {
      var linkPage = e.replace('#webUrl#', this.liffLanding)
      this.copyLinkB = linkPage
      this.copyLinkB_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
      if (!this.isDataImageB) {
        this.imageMenuBUrl = this.getDefaultMenuImage(e)
        fileReader.getBaseImage(this.imageMenuBUrl).then(data => {
          this.params.imagemenub = fileReader.blobToFile(data, 'imagemenub')
        })
      }
    },
    changePageC(e) {
      var linkPage = e.replace('#webUrl#', this.liffLanding)
      this.copyLinkC = linkPage
      this.copyLinkC_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
      if (!this.isDataImageC) {
        this.imageMenuCUrl = this.getDefaultMenuImage(e)
        fileReader.getBaseImage(this.imageMenuCUrl).then(data => {
          this.params.imagemenuc = fileReader.blobToFile(data, 'imagemenuc')
        })
      }
    },
    changePageD(e) {
      var linkPage = e.replace('#webUrl#', this.liffLanding)
      this.copyLinkD = linkPage
      this.copyLinkD_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
      if (!this.isDataImageD) {
        this.imageMenuDUrl = this.getDefaultMenuImage(e)
        fileReader.getBaseImage(this.imageMenuDUrl).then(data => {
          this.params.imagemenud = fileReader.blobToFile(data, 'imagemenud')
        })
      }
    },
    changePageE(e) {
      var linkPage = e.replace('#webUrl#', this.liffLanding)
      this.copyLinkE = linkPage
      this.copyLinkE_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
      if (!this.isDataImageE) {
        this.imageMenuEUrl = this.getDefaultMenuImage(e)
        fileReader.getBaseImage(this.imageMenuEUrl).then(data => {
          this.params.imagemenue = fileReader.blobToFile(data, 'imagemenue')
        })
      }
    },
    changePageF(e) {
      var linkPage = e.replace('#webUrl#', this.liffLanding)
      this.copyLinkF = linkPage
      this.copyLinkF_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )

      if (!this.isDataImageF) {
        this.imageMenuFUrl = this.getDefaultMenuImage(e)
        fileReader.getBaseImage(this.imageMenuFUrl).then(data => {
          this.params.imagemenuf = fileReader.blobToFile(data, 'imagemenuf')
        })
      }
    },
    changeLinkA(e) {
      this.copyLinkA = e.target.value
      this.copyLinkA_pc = e.target.value
    },
    changeLinkB(e) {
      this.copyLinkB = e.target.value
      this.copyLinkB_pc = e.target.value
    },
    changeLinkC(e) {
      this.copyLinkC = e.target.value
      this.copyLinkC_pc = e.target.value
    },
    changeLinkD(e) {
      this.copyLinkD = e.target.value
      this.copyLinkD_pc = e.target.value
    },
    changeLinkE(e) {
      this.copyLinkE = e.target.value
      this.copyLinkE_pc = e.target.value
    },
    changeLinkF(e) {
      this.copyLinkF = e.target.value
      this.copyLinkF_pc = e.target.value
    },
    changeCampaignA(e) {
      var linkPage = this.liffLanding + '?target_page=detail/' + e
      this.copyLinkA = linkPage
      this.copyLinkA_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
    },
    changeCampaignB(e) {
      var linkPage = this.liffLanding + '?target_page=detail/' + e
      this.copyLinkB = linkPage
      this.copyLinkB_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
    },
    changeCampaignC(e) {
      var linkPage = this.liffLanding + '?target_page=detail/' + e
      this.copyLinkC = linkPage
      this.copyLinkC_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
    },
    changeCampaignD(e) {
      var linkPage = this.liffLanding + '?target_page=detail/' + e
      this.copyLinkD = linkPage
      this.copyLinkD_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
    },
    changeCampaignE(e) {
      var linkPage = this.liffLanding + '?target_page=detail/' + e
      this.copyLinkE = linkPage
      this.copyLinkE_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
    },
    changeCampaignF(e) {
      var linkPage = this.liffLanding + '?target_page=detail/' + e
      this.copyLinkF = linkPage
      this.copyLinkF_pc = linkPage.replace(
        'line://app/',
        'https://liff.line.me/',
      )
    },
    getDefaultMenuImage(menuid) {
      var result = this.defaultImgMenuA + '?v=' + new Date().getTime()
      if (menuid == this.connectionA.page[0].id) {
        result = this.defaultImgHome + '?v=' + new Date().getTime()
      } else if (menuid == this.connectionA.page[2].id) {
        result = this.defaultImgMenuD + '?v=' + new Date().getTime()
      } else if (menuid == this.connectionA.page[3].id) {
        result = this.defaultImgMenuF + '?v=' + new Date().getTime()
      }

      return result
    },
    handleSubmit(e) {
      e.preventDefault()
      this.visibleUploadModal = true
      this.form.validateFields((err, values) => {
        if (err) {
          console.log('Received values of form: ', values)
          this.visibleUploadModal = false
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            this.lbl['alert-box-error-fill-info'],
            'error',
            true,
          )
        } else {
          // Convert json file to text
          var jsonContent = JSON.stringify(RichmenuTempl)
          // Convert text json to array
          var jsonArr = JSON.parse(jsonContent)
          var strCampaignUri = '#webUrl#?target_page=detail/'
          var strLinkUri = '#webUrl#?target_page=ecomurl&redirectUri='
          console.log('values', values)
          //#region Page
          if (values.connectionPageA)
            jsonArr.areas[0].action.uri = values.connectionPageA
          if (values.connectionPageB)
            jsonArr.areas[1].action.uri = values.connectionPageB
          if (values.connectionPageC)
            jsonArr.areas[2].action.uri = values.connectionPageC
          if (values.connectionPageD)
            jsonArr.areas[3].action.uri = values.connectionPageD
          if (values.connectionPageE)
            jsonArr.areas[4].action.uri = values.connectionPageE
          if (values.connectionPageF)
            jsonArr.areas[5].action.uri = values.connectionPageF
          //#endregion

          //#region Link
          if (values.connectionLinkA) {
            jsonArr.areas[0].action.uri = this.AllowBrandA
              ? strLinkUri + values.connectionLinkA
              : values.connectionLinkA
            this.params.redirect_url_a = values.connectionLinkA
          }

          if (values.connectionLinkB) {
            jsonArr.areas[1].action.uri = this.AllowBrandA
              ? strLinkUri + values.connectionLinkB
              : values.connectionLinkB
            this.params.redirect_url = values.connectionLinkB
          }

          if (values.connectionLinkC) {
            jsonArr.areas[2].action.uri = this.AllowBrandA
              ? strLinkUri + values.connectionLinkC
              : values.connectionLinkC
            this.params.redirect_url_c = values.connectionLinkC
          }

          if (values.connectionLinkD) {
            jsonArr.areas[3].action.uri = this.AllowBrandA
              ? strLinkUri + values.connectionLinkD
              : values.connectionLinkD
            this.params.redirect_url_d = values.connectionLinkD
          }

          if (values.connectionLinkE) {
            jsonArr.areas[4].action.uri = this.AllowBrandA
              ? strLinkUri + values.connectionLinkE
              : values.connectionLinkE
            this.params.redirect_url_e = values.connectionLinkE
          }

          if (values.connectionLinkF) {
            jsonArr.areas[5].action.uri = this.AllowBrandA
              ? strLinkUri + values.connectionLinkF
              : values.connectionLinkF
            this.params.redirect_url_f = values.connectionLinkF
          }

          //#endregion

          //#region Campaign
          if (values.connectionCampaignA) {
            jsonArr.areas[0].action.uri =
              strCampaignUri + values.connectionCampaignA
            this.params.campaignIdA = values.connectionCampaignA
          }

          if (values.connectionCampaignB) {
            jsonArr.areas[1].action.uri =
              strCampaignUri + values.connectionCampaignB
            this.params.campaignId = values.connectionCampaignB
          }

          if (values.connectionCampaignC) {
            jsonArr.areas[2].action.uri =
              strCampaignUri + values.connectionCampaignC
            this.params.campaignIdC = values.connectionCampaignC
          }

          if (values.connectionCampaignD) {
            jsonArr.areas[3].action.uri =
              strCampaignUri + values.connectionCampaignD
            this.params.campaignIdD = values.connectionCampaignD
          }

          if (values.connectionCampaignE) {
            jsonArr.areas[4].action.uri =
              strCampaignUri + values.connectionCampaignE
            this.params.campaignIdE = values.connectionCampaignE
          }

          if (values.connectionCampaignF) {
            jsonArr.areas[5].action.uri =
              strCampaignUri + values.connectionCampaignF
            this.params.campaignIdF = values.connectionCampaignF
          }

          //#endregion
          if (this.radioA == 4) {
            var index = jsonArr.areas
              .map(x => {
                return x.key
              })
              .indexOf(1)

            jsonArr.areas.splice(index, 1)
          } else if (
            !values.connectionPageA &&
            !values.connectionLinkA &&
            !values.connectionCampaignA
          ) {
            jsonArr.areas[0].action.uri = '#webUrl#?target_page='
          }
          if (this.radioB == 4) {
            let index = jsonArr.areas
              .map(x => {
                return x.key
              })
              .indexOf(2)

            jsonArr.areas.splice(index, 1)
          } else if (
            !values.connectionPageB &&
            !values.connectionLinkB &&
            !values.connectionCampaignB
          ) {
            jsonArr.areas[1].action.uri = '#webUrl#?target_page='
          }
          if (this.radioC == 4) {
            let index = jsonArr.areas
              .map(x => {
                return x.key
              })
              .indexOf(3)

            jsonArr.areas.splice(index, 1)
          } else if (
            !values.connectionPageC &&
            !values.connectionLinkC &&
            !values.connectionCampaignC
          ) {
            jsonArr.areas[2].action.uri = '#webUrl#?target_page='
          }
          if (this.radioD == 4) {
            let index = jsonArr.areas
              .map(x => {
                return x.key
              })
              .indexOf(4)

            jsonArr.areas.splice(index, 1)
          } else if (
            !values.connectionPageD &&
            !values.connectionLinkD &&
            !values.connectionCampaignD
          ) {
            jsonArr.areas[3].action.uri = '#webUrl#?target_page='
          }
          if (this.radioE == 4) {
            let index = jsonArr.areas
              .map(x => {
                return x.key
              })
              .indexOf(5)

            jsonArr.areas.splice(index, 1)
          } else if (
            !values.connectionPageE &&
            !values.connectionLinkE &&
            !values.connectionCampaignE
          ) {
            jsonArr.areas[4].action.uri = '#webUrl#?target_page='
          }
          if (this.radioF == 4) {
            let index = jsonArr.areas
              .map(x => {
                return x.key
              })
              .indexOf(6)

            jsonArr.areas.splice(index, 1)
          } else if (
            !values.connectionPageF &&
            !values.connectionLinkF &&
            !values.connectionCampaignF
          ) {
            jsonArr.areas[5].action.uri = '#webUrl#?target_page='
          }

          if (jsonArr) this.params.json = JSON.stringify(jsonArr)
          BzbsSettingsLine.postLineRichMenu(this.params)
            .then(res => {
              this.visibleUploadModal = false
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                '',
                'success',
                true,
              )
              console.log(res)
            })
            .catch(error => {
              this.visibleUploadModal = false
              console.log(error)
              this.alertModalAction(
                this.lbl['alert-box-error-header'],
                error.response.data.error.message,
                'error',
                true,
              )
            })
        }
      })
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
        }, 3500)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
        setTimeout(() => {
          this.alertModalSetting.title = ''
          this.alertModalSetting.details = ''
          this.alertModalSetting.modalType = ''
        }, 3500)
      }
    },
  },
}
</script>

<style lang="scss">
.wrap-richMenu {
  .ant-form-explain,
  .ant-form-extra {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-select.ant-select-enabled.ant-select-lg {
    width: 100%;
  }

  .ant-btn {
    height: 34px;
    line-height: 1.5;
  }

  .btn-upload-img {
    color: #fff;
    background-color: #9fa6ac;
    border-color: #9fa6ac;
    cursor: pointer;
  }

  .btn-upload-img:hover {
    color: #212529;
    text-decoration: none;
  }

  .imagerich {
    min-height: 193px;
    background-size: 100% 100% !important;
    background: no-repeat;
    width: 100%;
  }

  .wrapper-blog-richmenu {
    padding: 0 28px 0 40px;
  }

  .corner-flag-desc {
    position: absolute;
    z-index: 2;
    padding-left: 0.4rem;
    padding-top: 0.2rem;
    color: #fff;
    font-weight: 600;
  }

  .richemenu-corner-flag {
    width: 42px;
    height: 42px;
    position: absolute;
    left: -1px;
    top: -1px;
  }

  .corner-desc {
    z-index: 2;
    padding-left: 0.4rem;
    padding-top: 0.2rem;
    font-weight: 600;
    text-align: left;
  }

  .b-desc {
    color: #808080;
    font-size: 0.9rem;
  }

  .richemenu-upload-img-preview {
    margin: 10px;
    width: 120px !important;
    height: 110px !important;
    height: auto;
    background: no-repeat !important;
  }

  .choose-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  img.delete-image {
    width: 15px;
    height: auto;
    cursor: pointer;
  }

  .text-ellip {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 320px) and (orientation: portrait) {
    .wrapper-blog-richmenu {
      padding: 0px 30px 0 30px;
      margin-bottom: 25px;
    }

    .pr-5,
    .px-5 {
      padding-right: 15px !important;
    }

    .pl-2,
    .px-2 {
      padding-left: 15px !important;
    }
  }

  //แนวตั้ง mobile
  @media only screen and (min-device-width: 321px) and (max-device-width: 767px) and (orientation: portrait) {
    .wrapper-blog-richmenu {
      padding: 0px 60px 0 60px;
      margin-bottom: 25px;
    }

    .pr-5,
    .px-5 {
      padding-right: 15px !important;
    }

    .pl-2,
    .px-2 {
      padding-left: 15px !important;
    }
    .radio-link {
      padding-left: 0px !important;
    }
  }

  //แนวนอน mobile
  @media only screen and (max-device-width: 568px) and (orientation: landscape) {
    .wrapper-blog-richmenu {
      padding: 0px 150px 0 150px;
      margin-bottom: 25px;
    }

    .pr-5,
    .px-5 {
      padding-right: 15px !important;
    }

    .pl-2,
    .px-2 {
      padding-left: 15px !important;
    }
  }

  //แนวนอน mobile
  @media only screen and (min-device-width: 569px) and (max-device-width: 768px) and (orientation: landscape) {
    .wrapper-blog-richmenu {
      padding: 0px 50px 0 50px;
      margin-bottom: 25px;
    }

    .pr-5,
    .px-5 {
      padding-right: 15px !important;
    }

    .pl-2,
    .px-2 {
      padding-left: 15px !important;
    }
  }
}
@media only screen and (max-device-width: 821px) {
  .radio-link {
    padding-left: 28px !important;
  }
}
</style>
